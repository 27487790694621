import React, { useMemo } from 'react';
import Img from 'gatsby-image';


const ImgW = ({ fluid: fluidProp, alt, center = false }) => {
  const { presentationWidth } = fluidProp;

  const style = useMemo(() => ({
    maxWidth: `${presentationWidth}px`
  }), [ presentationWidth ]);

  return (
    <div className={`imgW${center ? ' center' : ''}`} style={style}>
      <Img fluid={fluidProp} alt={alt || ''} />
    </div>
  );
}

export default ImgW;
